import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import {
  CaseStudySummary,
  ClientList,
  Icon,
  Layout,
  PageSection,
  SEO,
} from "../../components"

export default () => {
  const data = useStaticQuery(graphql`
    query WorkPageQuery {
      site {
        siteMetadata {
          links {
            email {
              label
              url
            }
          }
          clients {
            logo
            name
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/work/" } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          frontmatter {
            slug
            title
            dates
            date(formatString: "MMMM DD, YYYY")
            technologies
            location
          }
        }
      }
    }
  `)

  const { siteMetadata } = data.site
  const { clients } = siteMetadata

  const { nodes: caseStudies } = data.allMarkdownRemark

  return (
    <Layout headerTheme="light">
      <>
        <SEO title="Work" />

        <PageSection
          id="partners-and-friends"
          theme="light"
          title="Partners and friends"
          intro="A handful of the amazing businesses and teams I’ve worked with in my career so far."
        >
          <ClientList clients={clients}></ClientList>
        </PageSection>

        <PageSection
          id="recent-experience"
          theme="mid"
          variant="left"
          title="A selection of my recent experience."
          intro="For more information and references, please
        <a href='/contact'>get in touch</a>."
        >
          <div className="case-study-summaries u-clearfix">
            {caseStudies.map(caseStudy => {
              return (
                <CaseStudySummary
                  key={caseStudy.frontmatter.title}
                  caseStudy={caseStudy.frontmatter}
                />
              )
            })}
          </div>
        </PageSection>

        <PageSection
          id="work-with-me"
          theme="dark"
          title="Want to work together?"
        >
          <div className="content  content--compact">
            <p>
              I’m always up for new challenges where I can collaborate with
              people and companies to realise their products. Don’t be a
              stranger, say hello!
            </p>

            <Link to="/contact" className="page-section__cta">
              Get in touch <Icon name="arrow-right" fill="#B7B7B7" />
            </Link>
          </div>
        </PageSection>
      </>
    </Layout>
  )
}
